@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.container {
  display: flex;
  flex-direction: column;
  margin: $standardSpacing2;

  form > div {
    width: 100%;
  }

  h2 {
    font-size: $fontSizeLarge;
  }
}

.card {
  background-color: $cardBackgroundColor;
  word-break: break-word;
  border-radius: $borderRadius;
  border: 1px solid $borderColor;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: $standardSpacing3;
  margin-bottom: $standardSpacing3;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    ul {
      margin-left: $standardSpacing2;
    }
  }

  li {
    margin-top: $standardSpacing2;
    border: 1px solid $borderColor;
    border-left: 4px solid $borderColor;
    padding: $standardSpacing2;
    border-radius: $borderRadiusHalf;
    display: flex;
    justify-content: space-between;

    .actions {
      min-width: 100px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }

    .reportName {
      word-break: break-all;
    }

    .share {
      color: #868e96;
    }
  }
}

.formActions {
  display: flex;
  justify-content: flex-end;
}

.previewReportBtn {
  margin: 0 $standardSpacing2 $standardSpacing3 $standardSpacing2;
}

.graph {
  box-sizing: border-box;
  margin: 0 $standardSpacing2;
}

@media screen and (min-width: $breakpoint-small) {
  .container {
    justify-content: space-evenly;

    & > div {
      width: 100%;
    }

    & > div:first-child {
      margin-right: $standardSpacing2;
    }
  }

  @media screen and (min-width: $breakpoint-medium) {
    .container {
      flex-direction: row;
    }
  }
}
