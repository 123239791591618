@import '../../../../assets/style/colors';
@import '../../../../assets/style/variables';

.itemsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  margin: $standardSpacing2;

  p {
    font-size: $fontSizeSmall;
  }
}
