.table {
  min-width: 640;
}
.visuallyHidden {
  border: 0;
  height: 1;
  overflow: hidden;
  padding: 0;
  position: absolute;
}
.tableHead {
  font-weight: bold;
}
