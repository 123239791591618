@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.header {
  background-color: $headingBackgroundColor;
  color: $headingTextColor;
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 998;
  top: 0;
}

.container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 100%;
  margin: 0 auto;

  .containerWidth {
    max-width: $pageWidth;
  }

  .logoWithText {
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  .desktopMenu {
    display: flex;
    align-items: center;
  }

  @media (max-width: $breakpoint-small) {
    padding: 0 $standardSpacing2;
    .desktopMenu,
    .customer {
      display: none;
    }
  }

  @media (min-width: $breakpoint-small) {
    .kebabMenu,
    .leftMenuOpen,
    .menuItemsPhone {
      display: none;
      visibility: hidden;
    }
  }

  .customer {
    margin-left: 30px;
    color: rgba($headingTextColor, 0.7);
  }
}

a.headerLink {
  margin-right: 30px;

  &:link,
  &:visited,
  &:active,
  &:hover {
    text-decoration: none;
    color: $headingTextColor;
  }

  &:hover {
    text-decoration: underline;
  }
}

.desktopMenuList {
  z-index: 999;
  & > * {
    color: $secondaryTextColor;
  }
}

.logotype {
  height: 100%;
  width: auto;
  height: auto;
}

.settings {
  background-color: $headingBackgroundColor;
  color: white;

  li {
    margin: 0;
  }
}

.menuItemsPhone,
.settings > * {
  display: block;
  padding: 15px;
}

.menuItemsPhone {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: $headingBackgroundColor;

  ul {
    list-style-type: none;
    margin: $standardSpacing2 0;
    padding: 0;
    li {
      & ul li {
        padding-left: $standardSpacing3;
      }
      a {
        height: 100%;
        width: 100%;
        padding: $standardSpacing2;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        svg {
          margin-right: $standardSpacing3;
        }

        &:link,
        &:visited,
        &:active,
        &:hover {
          text-decoration: none;
        }
        &:hover {
          background-color: #eff3f3;
        }
      }
    }
  }

  .leftMenuClose {
    align-self: flex-end;
    color: $headingBackgroundColor;
  }
}

.kebabMenu,
.leftMenuOpen {
  color: $headingTextColor !important;
}
