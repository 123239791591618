@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.cardContainer {
  word-wrap: break-word;
  color: $primaryMatchingColor;
  border-radius: $borderRadius;
  border: 1px solid $borderColor;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &:hover {
    opacity: 0.85;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
    color: $primaryMatchingColor;
  }

  header {
    padding: $standardSpacing3;
    display: block;
  }

  h2 {
    font-size: $standardSpacing3;
    line-height: 1.2;
    min-height: 100%;
    height: 100%;
    display: block;

    svg {
      float: left;
      margin-right: $standardSpacing2;
    }
  }

  footer {
    margin-left: auto;
    margin-top: auto;
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    border-top: 1px solid $borderColor;
    padding: $standardSpacing2;

    div {
      display: flex;
      align-items: center;
    }
  }
}

.primary {
  background-color: $backgroundColor2;
}

.secondary {
  background-color: $backgroundColor3;
}

@media screen and (min-width: $breakpoint-small) {
  .cardContainer {
    h2 {
      font-size: $fontSizeLarge;
    }
  }

  .minHeight150 {
    min-height: 150px;
  }
}
