@import '../../../assets/style/variables';

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logotypeContainer {
  max-width: 80%;
  margin: 0 auto;
}

@media (min-width: $breakpoint-small) {
  .logotypeContainer {
    max-width: 40%;
  }
}

.titleText {
  text-align: center;
  font-size: 28px;
}
