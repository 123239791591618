@import '../../../assets/style/variables';

.top {
  margin: $standardSpacing2 $standardSpacing3;
  font-size: $fontSizeLarge;
  text-align: center;

  @media (min-width: $breakpoint-small) {
    text-align: left;
  }

  @media (min-width: $breakpoint-medium) {
    font-size: 32px;
  }
}
