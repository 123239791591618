$breakpoint-small: 600px;
$breakpoint-medium: 1024px;

$standardSpacing1: 4px;
$standardSpacing2: 8px;
$standardSpacing3: 16px;

$fontSizeSmall: 12px;
$fontSizeMedium: 16px;
$fontSizeLarge: 22px;

$pageWidth: 1024px;

$borderRadius: 6px;
$borderRadiusHalf: 6px;
