@import '../../../assets/style/variables';
@import '../../../assets/style/colors';

.main {
  height: calc(100vh - 60px);
  margin: 0 auto;
  max-width: $pageWidth;
  box-sizing: border-box;
  padding: $standardSpacing3;
}
.mainContent {
  flex-grow: 1;
}

.rightMenu {
  border-right: 1px solid $successAlertBackgroundColor;
  background-color: white;
  box-sizing: border-box;
  min-width: 260px;
  ul {
    list-style-type: none;
    margin: $standardSpacing2 0;
    padding: 0;
    li {
      & ul li {
        padding-left: $standardSpacing3;
      }
      a {
        height: 100%;
        width: 100%;
        padding: $standardSpacing2;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        svg {
          margin-right: $standardSpacing3;
        }

        &:link,
        &:visited,
        &:active,
        &:hover {
          text-decoration: none;
        }
        &:hover {
          background-color: #eff3f3;
        }
      }
    }
  }
}
