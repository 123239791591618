@import '../../../assets/style/variables';

.main {
  background-color: #bedce6;
  background-image: url(../../../assets/images/prognos_grafiskt_element.png);
  background-size: cover;
  background-position: center 50px;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100vw;
  align-items: center;
  display: flex;
  justify-content: center;
}

.box {
  box-sizing: border-box;
  background-color: white;
  margin-top: 40px;
  padding: 30px;
  width: 96%;
  position: relative;
  min-height: 320px;
}

@media (min-width: $breakpoint-small) {
  .box {
    width: 460px;
    min-height: 390px;
  }
}
