@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.collectionList {
  list-style-type: none;
  margin: $standardSpacing1 0 0 0;
  padding: 0;

  li {
    margin-bottom: $standardSpacing2;
    border: 1px solid $borderColor;
    border-radius: $borderRadiusHalf;
    word-wrap: break-word;
    overflow-wrap: break-word;
    background-color: $boxBackgroundColor;

    header {
      display: flex;
      justify-content: space-between;
      padding: $standardSpacing2 $standardSpacing2;

      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    & > div {
      padding: $standardSpacing1 $standardSpacing3;

      svg {
        margin-left: 0;
        padding: 0;
      }
    }

    strong {
      display: inline-flex;
      align-items: center;
    }
  }

  .reportBox {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-child(even) {
      background-color: $listColorEven;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $borderColor;
    }

    div {
      display: inline-flex;
      align-items: center;
    }
  }

  @media screen and (min-width: $breakpoint-small) {
    a {
      flex-direction: row;
      align-items: center;
    }
  }
}

.tablePageHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: $standardSpacing3;

  & > h2 {
    font-size: $fontSizeLarge;
  }
}
