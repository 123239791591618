@import '../../assets/style/variables';

.pageHeader {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: $standardSpacing2;

  h2 {
    font-size: $fontSizeMedium;
    width: 100%;
    text-align: center;

    @media (min-width: $breakpoint-medium) {
      font-size: $fontSizeLarge;
    }
  }

  button {
    white-space: nowrap;
  }
}

.search {
  max-width: 200px;
  position: relative;
  border-radius: $standardSpacing2;
  background-color: #fff;

  &:hover {
    background-color: #fefefe;
  }
  width: 100%;
}
.searchIcon {
  color: #919191;
  padding: 0 6px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16px;
    height: 16px;
  }
}
.inputRoot {
  color: inherit;
}
.inputInput {
  padding: 4px 2px 0 28px;
  width: 100%;
  font-size: $fontSizeSmall !important;
}
