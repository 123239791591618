@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.userBox {
  background-color: $boxBackgroundColor;
  padding: $standardSpacing2;
  margin: $standardSpacing2;
  display: flex;
  align-items: center;
  word-break: break-all;

  div {
    strong {
      display: block;
      margin-bottom: $standardSpacing1;
    }

    span {
      font-size: 0.8rem;
      color: $secondaryTextColor;
    }
  }
}
.search {
  max-width: 300px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  &:hover {
    background-color: #fefefe;
  }
  margin-left: 0;
  width: 100%;
}
.searchIcon {
  color: #919191;
  padding: 0 6px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputRoot {
  color: inherit;
}
.inputInput {
  padding: 6px;
  padding-left: 34px;
  width: 100%;
}
.tablePageHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 $standardSpacing2;
}
.tableHead {
  font-weight: bold;
}
