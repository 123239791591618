$primaryColor: #19555a;
$primaryMatchingColor: #f4f7f9;

$secondaryTextColor: #393f3f;
$textColor: #000000;
$linkTextColor: #18575a;

$headingBackgroundColor: #0a3c46;
$headingTextColor: #fff;

$borderColor: rgba(0, 0, 0, 0.15);

$thirdBackgroundColor: #19345a;

$backgroundColor2: #6ea5af;

$backgroundColor3: #919191;

$infoColor: #fff;

$infoColorBackground: #131616;
$successAlertBackgroundColor: rgb(238, 248, 238);
$successAlertColor: rgb(30, 70, 32);
$boxBackgroundColor: #fff;

$backgroundColor4: #f8f9fa;
$backgroundColor4Darker: #eeeeee;

$infoPrimaryBackgroundColor: #2f96b4;
$infoPrimaryColor: #fff;

$successBackgroundColor: #4caf50;
$successColor: #fff;

$listColorEven: #fcfdfd;

$deleteColor: #fff;

$alertWarningBackgroundColor: rgb(255, 244, 229);
$alertWarningColor: rgb(102, 60, 0);

$deleteBackgroundColor: rgb(220, 0, 78);
$deleteBackgroundHoverColor: rgb(190, 1, 67);

$cardBackgroundColor: #fff;

$addBackgroundColor: #28a745;
$addBackgroundColorHover: #208537;
