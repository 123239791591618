@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.header {
  background-color: $headingBackgroundColor;
  color: $headingTextColor;
  height: 60px;
}

.container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  height: 100%;
  margin: 0 auto;
  max-width: $pageWidth;

  div {
    display: flex;
    align-items: center;
  }

  @media (max-width: $breakpoint-small) {
    .desktopMenu,
    .customer {
      display: none;
    }
  }

  @media (min-width: $breakpoint-small) {
    .kebabMenu {
      display: none;
      visibility: hidden;
    }
  }

  .customer {
    margin-left: 30px;
    color: rgba($headingTextColor, 0.7);
  }
}

a.headerLink {
  margin-right: 30px;

  &:link,
  &:visited,
  &:active,
  &:hover {
    text-decoration: none;
    color: $headingTextColor;
  }

  &:hover {
    text-decoration: underline;
  }
}

.desktopMenuList {
  & > * {
    color: $secondaryTextColor;
  }
}

.logotype {
  height: 100%;
  width: auto;
  height: auto;
}

.menuItemsPhone {
  background-color: $headingBackgroundColor;
  color: white;

  li {
    margin: 0;
  }
}

.menuItemsPhone > * {
  display: block;
  margin: 15px;
}

.kebabMenu {
  color: $headingTextColor !important;
}
