@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.card {
  background-color: $cardBackgroundColor;
  word-break: break-word;
  border-radius: $borderRadius;
  border: 1px solid $borderColor;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: $standardSpacing3;
  margin-bottom: $standardSpacing3;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    ul {
      margin-left: $standardSpacing2;
    }
  }

  li {
    margin-top: $standardSpacing2;
    border: 1px solid $borderColor;
    border-left: 4px solid $borderColor;
    padding: $standardSpacing2;
    border-radius: $borderRadiusHalf;
    display: flex;
    justify-content: space-between;

    .actions {
      min-width: 100px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }

    .reportName {
      word-break: break-all;
    }

    .share {
      color: #868e96;
    }
  }
}
.hiddenInReport {
  color: $infoPrimaryBackgroundColor;
  margin-left: $standardSpacing1;
  font-size: 14px;
}

.formHeader {
  h2 {
    display: inline-block;
  }
}

.formActions {
  display: flex;
  justify-content: flex-end;
}

.inputWithTooltip {
  display: flex;
  align-items: center;
  gap: $standardSpacing1;
  width: 100%;
  justify-content: space-between;
}

.questionmarkIcon {
  padding: $standardSpacing1;
  border-radius: 50%;
  background-color: $primaryColor;
  color: white;
}
.inputBasic {
  margin-right: 24px;
}

.fullWidth {
  width: 100%;
}
