@import '../../assets/style/colors';
@import '../../assets/style/variables';

.button {
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 4px;
  color: $primaryMatchingColor;
  border: 0;
  outline: 0;
  text-align: center;
  transition: 0.2s ease-out;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);

  &:before {
    content: '';
    position: absolute;
    vertical-align: middle;
    z-index: 1;
    will-change: opacity, transform;
    background-color: rgb(240, 240, 240);
    opacity: 0.2;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    width: 0;
    padding-top: 0;
    transform: translate(-50%, -50%);
  }

  &:hover {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.16), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: darkgray;
    opacity: 1;
  }

  &:focus {
    box-shadow: 0 0 2pt 2pt black;
  }

  &:active:before {
    width: 110%;
    padding-top: 110%;
    transition: width 0.2s ease-out, padding-top 0.2s ease-out;
  }
}

.round {
  padding: 2px;
  border-radius: 50% !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.primary {
  background-color: $primaryColor;

  &:hover:enabled {
    background-color: lighten($primaryColor, 2);
  }
}

.add {
  background-color: $addBackgroundColor;

  &:hover:enabled {
    background-color: $addBackgroundColorHover;
  }
}

.third {
  background-color: $thirdBackgroundColor;

  &:hover:enabled {
    background-color: lighten($thirdBackgroundColor, 2);
  }
}

.iconSecondary {
  background-color: #fff;

  &:hover:enabled {
    background-color: $secondaryTextColor;
  }
}

.secondary {
  background-color: none;
  color: $secondaryTextColor;

  &:hover:enabled {
    background-color: none;
  }
}

.delete {
  background-color: $deleteBackgroundColor;
  color: $deleteColor;

  &:hover:enabled {
    background-color: $deleteBackgroundHoverColor;
  }
}

.buttonContainer {
  display: inline-flex;
  align-items: center;
}

.buttonWrapper {
  position: relative;
}

.buttonProgress {
  position: absolute;
  top: 30%;
  left: 40%;
  margin-top: -10;

  svg {
    color: $secondaryTextColor;
  }
}

.small {
  font-size: $fontSizeSmall;
  padding: 4px 6px;
}

.text {
  background-color: none;
  color: $primaryColor;
  padding: 0;
  font-size: 100% !important;
  border: 0;
  border-radius: 0 !important;
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  text-decoration: underline;

  &:hover {
    font-weight: 700;
    cursor: pointer;
  }
}

.icon {
  display: flex;
  align-items: center;
  gap: $standardSpacing2;
}
