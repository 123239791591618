@import '../../../assets/style/variables';

.logotypeContainer {
  max-width: 80%;
}

@media (min-width: $breakpoint-small) {
  .logotypeContainer {
    max-width: 50%;
  }
}

.footer {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 20px;
  left: 0;
}
