@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.main {
  height: calc(100vh - 60px);
  box-sizing: border-box;

  @media (min-width: $breakpoint-small) {
    display: grid;
    grid-template-columns: 260px auto;
  }
}

.mainContent {
  margin-top: 60px;
  flex-grow: 1;
  overflow-x: scroll;
  height: 100%;
}

.leftMenu {
  border-right: 1px solid $successAlertBackgroundColor;
  background-color: white;
  box-sizing: border-box;
  width: 260px;
  height: 100%;
  margin-top: 60px;
  ul {
    list-style-type: none;
    margin: $standardSpacing2 0;
    padding: 0;
    li {
      & ul li {
        padding-left: $standardSpacing3;
      }
      a {
        height: 100%;
        width: 100%;
        padding: $standardSpacing2;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        svg {
          margin-right: $standardSpacing3;
        }

        &:link,
        &:visited,
        &:active,
        &:hover {
          text-decoration: none;
        }
        &:hover {
          background-color: #eff3f3;
        }
      }
    }
  }
  @media (max-width: $breakpoint-small) {
    display: none;
    visibility: hidden;
  }
}

.kebabWrapper {
  @media (min-width: $breakpoint-small) {
    display: none;
    visibility: hidden;
  }

  .kebabMenu {
    color: $linkTextColor !important;
  }
}

.desktopMenuList {
  & > * {
    color: $secondaryTextColor;
  }
}

.menuItemsPhone {
  margin: $standardSpacing3 $standardSpacing2;
  color: $linkTextColor;

  li {
    margin: 0;
  }
}
