@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.container {
  background-color: $cardBackgroundColor;
  margin: $standardSpacing2;
  padding: $standardSpacing3;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: $standardSpacing2;
  border-radius: 6px;

  .buttons {
    display: flex;
    gap: $standardSpacing2;
  }

  .updated {
    font-size: $fontSizeSmall;
  }

  @media screen and (min-width: $breakpoint-medium) {
    grid-template-rows: none;
    grid-template-columns: repeat(4, 1fr);
  }
}
