@font-face {
  font-family: PostGrotesk-Book;
  src: url('../fonts/PostGrotesk-Book_Web/PostGrotesk-Book.eot') format('eot');
  src: url('../fonts/PostGrotesk-Book_Web/PostGrotesk-Book.woff2') format('woff2'),
    url('../fonts/PostGrotesk-Book_Web/PostGrotesk-Book.woff') format('woff');
}

@font-face {
  font-family: PostGrotesk-Bold;
  src: url('../fonts/PostGrotesk-Bold_Web/PostGrotesk-Bold.eot') format('eot');
  src: url('../fonts/PostGrotesk-Bold_Web/PostGrotesk-Bold.woff2') format('woff2'),
    url('../fonts/PostGrotesk-Bold_Web/PostGrotesk-Bold.woff') format('woff');
}

@font-face {
  font-family: CentraleSansXBold;
  src: url('../fonts/CentraleSansXBold/376A0C_0_0.eot') format('eot');
  src: url('../fonts/CentraleSansXBold/376A0C_0_0.woff2') format('woff2'),
    url('../fonts/CentraleSansXBold/376A0C_0_0.woff') format('woff');
}

@import './colors';
@import '_spacing.scss';
@import '_variables.scss';
@import '_alert.scss';

html,
body,
* {
  font-family: PostGrotesk-Book, Sans-serif;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

main,
footer,
header {
  display: block;
}

body {
  background-color: #f5f5f5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-style: normal;
  font-family: CentraleSansXBold, Sans-serif;
}

strong {
  font-family: PostGrotesk-Bold, Sans-serif;
}

h1 {
  font-size: 200%;
}

body,
html,
#root {
  height: 100%;
  min-height: 100%;
}

img {
  width: 100%;
}

a {
  &:link,
  &:visited,
  &:active,
  &:hover {
    text-decoration: none;
    color: $linkTextColor;
  }

  &:hover {
    text-decoration: underline;
  }
}

.floatLeft {
  float: right;
}

.floatRight {
  float: right;
}

#root {
  height: 100vh;
}

.text-center {
  text-align: center;
}

.errorText {
  box-sizing: border-box;
  color: #72141d;
  background-color: #fadcde;
  padding: $standardSpacing3;
  margin-bottom: $standardSpacing3;
}

.isValidError {
  box-sizing: border-box;
  color: #72141d;
  background-color: #fadcde;
  text-align: center;
  padding: $standardSpacing1;
}
hr {
  width: 100%;
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: hidden;
  border-width: 0.1px;
  border-bottom: 1px solid $borderColor;
}

//input field
.MuiInput-underline:after {
  border-bottom-color: $primaryColor !important;
}

label.Mui-focused {
  color: #000 !important;
}

.nowrap {
  white-space: nowrap !important;
}

thead > tr > th {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.prognosTable tbody:nth-child(even) tr:nth-child(odd) {
  background-color: #f9fafa;
}

.prognosTable tbody:nth-child(even) tr:hover {
  background-color: #eff3f3;
}

nav .active {
  font-weight: bold;
  background-color: #f9fafa;
}

.activeListItem {
  background-color: #eff3f3;
}
.sm-text {
  font-size: 0.8rem;
}
