@import '_spacing.scss';
@import '_variables.scss';
@import '_colors.scss';


.alertWarning {
  background-color: $alertWarningBackgroundColor;
  color: $alertWarningColor;
  font-size: 0.9rem;
  padding: $standardSpacing1;
  border-radius: $borderRadius;
}


.alertSuccess {
  background-color: $successAlertBackgroundColor;
  font-size: 0.9rem;
  padding: $standardSpacing1;
  border-radius: $borderRadius;
}