$sides: ("a": "",
  "t": "top",
  "b": "bottom",
  "l": "left",
  "r": "right",
);

@each $sideName,
$sideValue in $sides {
  @for $i from 0 through 12 {
    $property: if($sideName=='a', '', -#{$sideValue});
    $space: $i * 4;
    $selector: '';
    $selector: #{$sideName}-#{$i};

    .m#{$selector} {
      margin#{$property}: #{$space}px !important;
    }

    .p#{$selector} {
      padding#{$property}: #{$space}px !important;
    }
  }
}