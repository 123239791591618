@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.main {
  h2 {
    font-size: $fontSizeLarge;
  }
}

ul.list {
  list-style-type: none;
  margin: $standardSpacing1 0 0 0;
  padding: 0;

  li {
    background-color: $boxBackgroundColor;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: $standardSpacing1;
    padding: $standardSpacing2 $standardSpacing2;
    border: 1px solid $borderColor;
    border-radius: $borderRadiusHalf;

    div:last-child {
      margin-top: $standardSpacing1;
    }

    span {
      font-size: $fontSizeSmall;
    }
  }
}

.textBox {
  display: flex;
  align-items: center;
}

@media screen and (min-width: $breakpoint-small) {
  ul.list {
    li {
      flex-direction: row;

      div:last-child {
        margin-top: 0;
      }
    }
  }
}

.search {
  max-width: 300px;
  position: relative;
  border-radius: 8px;
  background-color: #fff;
  &:hover {
    background-color: #fefefe;
  }
  margin-left: 0;
  width: 100%;
}
.searchIcon {
  color: #919191;
  padding: 0 6px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputRoot {
  color: inherit;
}
.inputInput {
  padding: 6px;
  padding-left: 34px;
  width: 100%;
}
.tablePageHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 $standardSpacing2;
}
.tableHead {
  font-weight: bold;
}
