@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.legendContainer {
  display: inline-flex;
  background-color: $backgroundColor4;
  border: 1px solid rgba(0, 0, 0, 0.05);
  padding: 6px;
  border-radius: $borderRadius;
  margin-right: 8px;
  margin-bottom: 8px;

  &:link,
  &:visited,
  &:active,
  &:hover {
    text-decoration: none;
  }
}

a.legendContainer {
  &:hover {
    background-color: $backgroundColor4Darker;
  }
}

.hrToolTip {
  border-bottom: 1px solid white;
  margin: $standardSpacing2 0 $standardSpacing2 0;
}

.verticalCenter {
  display: flex;
  align-items: center;
}

.legendLinkBox {
  font-size: 14px;
}

.colorBox {
  font-size: $fontSizeSmall;
  color: $primaryMatchingColor;
  margin-left: 8px;
  min-width: 18px;
  min-height: 14px;
  padding: 3px;
  display: inline-block;
  border-radius: 4px;
}
