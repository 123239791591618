@import '../../assets/style/colors';
@import '../../assets/style/variables';

.snackbar {
  padding: $standardSpacing3;
  border-radius: $borderRadius;
  line-height: 1.5;
}

.success {
  background-color: $successBackgroundColor;
  color: $successColor;
}

.info {
  background-color: $infoPrimaryBackgroundColor;
  color: $infoPrimaryColor;
}

.messageBox {
  display: flex;
  align-items: center;
  width: 100%;

  svg {
    margin-right: $standardSpacing3;
  }
}
