@import '../../assets/style/colors';
@import '../../assets/style/variables';

.cardContainer {
  background-color: $cardBackgroundColor;
  padding: $standardSpacing3;
  word-wrap: break-word;
  border-radius: $borderRadius;
  border: 1px solid $borderColor;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1024px;
  margin: 0 auto;

  header {
    justify-content: space-between;
    display: flex;
    align-items: center;

    h2 {
      font-weight: normal;
    }
  }
}

.footerChartDescription {
  display: flex;
  align-items: center;

  span {
    margin-right: $standardSpacing3;
  }

  :first-child {
    float: left;
    margin-right: $standardSpacing1;
  }

  :last-child {
    float: left;
    margin-right: $standardSpacing1;
  }
}

.footerDescriptionContainer {
  max-width: 60%;
  :last-child {
    max-width: 95%;
  }
}

.infoText {
  display: flex;
  align-items: center;
}

.footerDescription {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.fullWidthHr {
  width: calc(100% + #{$standardSpacing3} * 2);
  left: -$standardSpacing3;
  position: relative;
}

.headerRightContainer {
  display: flex;

  button:first-child {
    margin-right: $standardSpacing1;
  }
}

.chartTopSettings {
  margin-top: $standardSpacing2;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-height: 40px;
}

@media screen and (min-width: $breakpoint-small) {
  .chartTopSettings {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

.checkedButton {
  background-color: #868e96 !important;
  box-shadow: 0 0 0 0.2rem rgb(134 142 150 / 50%);
}

.zoomOptionContainer {
  display: flex;
  flex-direction: column;

  & > *:not(:first-child) {
    margin-left: 0;
    margin-top: $standardSpacing2;
  }
}

.zoomOption {
  display: flex;
  flex-direction: column;
  margin-top: $standardSpacing2;

  & > *:not(:first-child) {
    margin-top: $standardSpacing2;
  }
}

@media screen and (min-width: $breakpoint-small) {
  .zoomOptionContainer {
    flex-direction: row;
    margin-top: $standardSpacing2;

    & > *:not(:first-child) {
      margin-top: 0;
      margin-left: $standardSpacing3;
    }
  }

  .zoomOption {
    margin-top: 0;
    flex-direction: row;

    & > *:not(:first-child) {
      margin-top: 0;
      margin-left: $standardSpacing3;
    }
  }
}

.actionButton {
  color: $secondaryTextColor !important;
  border-color: $secondaryTextColor !important;
  &:hover {
    background-color: #f1f4f4 !important;
    border-color: $secondaryTextColor !important;
  }
}
